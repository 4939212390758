import { useCallback, useMemo } from 'react';
import { useAuth } from '@teamexos/fit-shared';
import debounce from 'lodash/debounce';
import { v4 as uuid } from 'uuid';
import { OrderDirection, useSelfQuery } from '../graphql/types';
import { useTrackingEventListContext } from '../Providers/TrackingEventListProvider';
import { mixpanelClient } from '../utils/mixpanelClient';
import {
  EventCategories,
  EventInstances,
  EventLabels,
  EventNames,
  EventProfileCardNames,
  EventSectionNames,
  EventSubCategories,
  ScreenNames,
} from '../utils/trackingEvents';
import { getScreenName } from './getScreenName';

type MPTrack = {
  selfId?: string;
  trackOrig: (name: string, cfg?: Record<string, unknown>) => void;
};

const useTrackingAnalytics = (): MPTrack => {
  const { isLoading: authIsLoading, isLoggedIn } = useAuth();
  const { data } = useSelfQuery({
    fetchPolicy: 'cache-first',
    skip: !isLoggedIn || authIsLoading,
  });

  const screenName = getScreenName(window?.location?.pathname);
  const { pushEvent } = useTrackingEventListContext();

  return {
    selfId: data?.self?.id,
    trackOrig: useCallback(
      (name, cfg?): void => {
        const genericData = {
          application_name: 'Coach Hub',
          is_admin: data?.self?.isAdmin,
          hub_instance: EventInstances.Fit,
          screen_name: screenName,
          sp_user_id: data?.self?.id,
        };
        const eventData = {
          ...genericData,
          ...cfg,
        };
        mixpanelClient?.track(name, eventData, () => {
          pushEvent(uuid(), name, { genericData, specificData: cfg });
        });
      },
      [data?.self?.id, data?.self?.isAdmin, pushEvent, screenName],
    ),
  };
};

export interface TrackingEventData {
  member_id?: string;
  user_id?: string;
  coachId?: string;

  activity_id?: string;
  chat_id?: string | null;
  message_id?: string | null;
  coversation_id?: string;
  survey_id?: string | null;
  session_id?: string;
  assessment_id?: string | null;
  touch_point_id?: string;
  program_id?: string;
  practice_id?: string;
  training_plan_id?: string;
  sort_type?: OrderDirection;
  position?: number;
  is_unfurl?: boolean;
  locked?: boolean;
  section_id?: string;
  plan_id?: string;
  plan_item_id?: string;
  content_id?: string;
  note_id?: string;

  card_name?: EventProfileCardNames | string;
  screen_name?: ScreenNames;
  event_category?: EventCategories;
  event_subcategory?: EventSubCategories | string;
  event_label?: EventLabels | string;
  section_name?: EventSectionNames | string;
}

export default function useTracking() {
  const { selfId, trackOrig } = useTrackingAnalytics();

  const track = useCallback(
    (name: EventNames, event: TrackingEventData = {}) => {
      trackOrig(name, { ...event });
    },
    [trackOrig],
  );

  const debouncedTrack = useMemo(() => debounce(track, 500), [track]);

  return { track, debouncedTrack, selfId };
}
