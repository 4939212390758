import React from 'react';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ExosLogo from '../../../assets/images/Exos-logomark.svg';
import useTracking, { TrackingEventData } from '../../../hooks/useTracking';
import { WHITE } from '../../../theme';
import { EventNames } from '../../../utils/trackingEvents';
import Text, { TextColor, TextKind } from '../Text';

const ButtonComponent: React.FC<{
  children: React.ReactNode;
  onClick: () => void;
  'data-testid': string;
}> = ({ children, ...props }) => (
  <button type="button" {...props}>
    {children}
  </button>
);
const BackButtonComponent = styled(ButtonComponent)`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${WHITE};
  cursor: pointer;
  display: flex;
  height: 40px;
`;

const IconComponent: React.FC<Omit<FontAwesomeIconProps, 'icon'>> = (props) => (
  <FontAwesomeIcon {...props} icon={faArrowLeft} />
);

const BackArrow = styled(IconComponent)`
  height: 16px;
  margin-right: 8px;
  width: 16px;
`;

interface Props {
  eventData?: TrackingEventData;
}

const BackButton: React.FC<Props> = ({ eventData }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMemberProfile = useMatch('/app/members/:memberId');
  const gameplanRegex =
    /\/app\/members\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/gameplan/;
  const isGameplanPage = gameplanRegex.test(location.pathname);

  const { track } = useTracking();

  const goBack = () => {
    track(EventNames.BackButtonClick, eventData);

    // state is only empty if there is no history
    if (window.history.state) {
      navigate(-1);
      return;
    }

    navigate('/app');
  };

  const handleBackClick = () => {
    goBack();
  };

  const shouldShowBackButton = isMemberProfile || isGameplanPage;

  if (shouldShowBackButton) {
    return (
      <BackButtonComponent onClick={handleBackClick} data-testid="back-button">
        <BackArrow />
        <Text
          color={TextColor.Contrast}
          kind={TextKind.BodyM}
          style={{ lineHeight: 1 }}
        >
          Back
        </Text>
      </BackButtonComponent>
    );
  }

  return <img src={ExosLogo} alt="Exos Logo" width={30} height={30} />;
};

export default BackButton;
