import React from 'react';
import { CoachType } from '../../../graphql/types';
import { SplitFlags } from '../../../hooks/featureFlags';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { EventLabels } from '../../../utils/trackingEvents';
import { NavigationItem, NavigationWrapper } from './Navigation';

interface Props {
  coachType: CoachType;
}

export const FitNavigation: React.FC<Props> = ({ coachType }: Props) => {
  const { flags } = useFeatureFlags();

  const showMethodologyBot = !!flags[SplitFlags.METHODOLOGY_BOT_ENABLED];
  const isHomeEnabledForNs = !!flags[SplitFlags.NS_HOME_PAGE];

  const showHome = coachType !== CoachType.Dietitian || isHomeEnabledForNs;

  return (
    <NavigationWrapper>
      <NavigationItem
        to="/app"
        testId="NavigationHomeLink-Fit"
        eventLabel={EventLabels.Home}
        show={showHome}
      >
        Home
      </NavigationItem>
      <NavigationItem
        to="/app/members"
        testId="NavigationMembersLink-Fit"
        eventLabel={EventLabels.Members}
        show={coachType !== CoachType.Dietitian}
      >
        Members
      </NavigationItem>
      <NavigationItem
        to="/app/sessions"
        testId="NavigationSessionsLink-Fit"
        eventLabel={EventLabels.SessionsTab}
      >
        Sessions
      </NavigationItem>
      <NavigationItem
        to="/app/methodology"
        testId="NavigationMethodologyLink-Fit"
        eventLabel={EventLabels.Methodology}
        show={showMethodologyBot}
      >
        Coach O - Methodology chatbot
      </NavigationItem>
    </NavigationWrapper>
  );
};
