import styled, { keyframes } from 'styled-components';

export const spinDuration = 600;

const SpinKeyFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spin = styled.div`
  animation: ${SpinKeyFrames} ${spinDuration}ms infinite linear;
`;
