import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import useTracking from '../../hooks/useTracking';
import {
  EventCategories,
  EventLabels,
  EventNames,
  ScreenNames,
} from '../../utils/trackingEvents';
import Input, { InputSize } from '../reusable/Input';
import { SignInFlow } from './types';

interface LoginProps {
  flow: SignInFlow;
  register: (inputName: string) => UseFormRegisterReturn;
}

export const LoginField: React.FC<LoginProps> = ({
  flow,
  register,
}: LoginProps) => {
  const { track } = useTracking();

  const trackTextBoxActivated =
    (label: EventLabels, screenName: ScreenNames) => () => {
      track(EventNames.TextBoxActivated, {
        event_category: EventCategories.SignIn,
        event_label: label,
        screen_name: screenName,
      });
    };

  switch (flow) {
    case SignInFlow.Password:
      return (
        <Input
          label="Password"
          type="password"
          id="login-password"
          size={InputSize.Large}
          autoFocus
          formRegister={register('password')}
          onFocus={trackTextBoxActivated(
            EventLabels.Password,
            ScreenNames.SignInPassword,
          )}
        />
      );
    case SignInFlow.Email:
      return (
        <Input
          label="Email address"
          id="login-email"
          size={InputSize.Large}
          autoFocus
          formRegister={register('email')}
          onFocus={trackTextBoxActivated(
            EventLabels.Email,
            ScreenNames.SignInEmail,
          )}
        />
      );
    default:
      return null;
  }
};
