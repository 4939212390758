import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import Text, { TextColor, TextKind } from './Text';

interface Props {
  name: string;
  errorTextKind?: TextKind;
}

const Errors: React.FC<Props> = ({ name, errorTextKind }) => (
  <ErrorMessage
    name={name}
    render={({ messages, message }) => {
      if (!message && !messages) {
        return null;
      }

      if (message) {
        return (
          <Text
            kind={errorTextKind || TextKind.BodyL}
            color={TextColor.Error}
            testId="formErrorMessage"
          >
            {message}
          </Text>
        );
      }

      if (!messages) {
        return null;
      }

      return Object.entries(messages).map(([type, msg]) => (
        <Text
          kind={errorTextKind || TextKind.BodyL}
          key={type}
          color={TextColor.Error}
          testId="formErrorMessage"
        >
          {msg}
        </Text>
      ));
    }}
  />
);

export default Errors;
