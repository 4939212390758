import { ApolloError } from '@apollo/client';
import {
  CalendarProfileDetailsFragment,
  ClientCalendarPlatform,
  ClientCalendarProfilesBasicsFragment,
  ClientConferencingProfilesBasicsFragment,
  ClientVideoPlatform,
  CoachDataBasicsFragment,
  ConferencingProfileDetailsFragment,
  ConnectionDetailsFragment,
  useGetConnectionDetailsQuery,
} from '../graphql/types';

export interface CoachConnectionDetails
  extends Partial<CoachDataBasicsFragment>,
    Partial<ConnectionDetailsFragment> {
  missingCronofyTimezone: boolean;
  hasAllRequiredProfiles: boolean;
  disconnectedCalendarProfiles: CalendarProfileDetailsFragment[] | undefined;
  missingRequiredCalendarProfiles:
    | ClientCalendarProfilesBasicsFragment[]
    | undefined;
  disconnectedConferencingProfiles:
    | ConferencingProfileDetailsFragment[]
    | undefined;
  missingRequiredConferencingProfiles:
    | ClientConferencingProfilesBasicsFragment[]
    | undefined;
  requiredCalendarProfiles: ClientCalendarProfilesBasicsFragment[] | undefined;
  requiredConferencingProfiles:
    | ClientConferencingProfilesBasicsFragment[]
    | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

export const useGetConnectionDetails = (): CoachConnectionDetails => {
  const { data, loading, error } = useGetConnectionDetailsQuery({
    fetchPolicy: 'cache-first',
  });
  const {
    calendarConnected,
    calendarProfiles,
    conferencingProfiles,
    clientRequiredCalendarProfiles,
    clientRequiredConferencingProfiles,
    cronofyTimezone,
  } = data?.self?.coachData || {};

  const requiredCalendarProfiles = clientRequiredCalendarProfiles?.edges
    .map((edge) => edge.node)
    ?.filter(
      (requiredProfile) =>
        requiredProfile.calendarPlatform !== ClientCalendarPlatform.ExosOnly,
    );

  const requiredConferencingProfiles = clientRequiredConferencingProfiles?.edges
    .map((edge) => edge.node)
    ?.filter(
      (requiredProfile) =>
        requiredProfile.videoPlatform !== ClientVideoPlatform.Integrated,
    );

  const disconnectedCalendarProfiles = calendarProfiles?.filter(
    (profile) => !profile.profileConnected,
  );
  const disconnectedConferencingProfiles = conferencingProfiles?.filter(
    (profile) => !profile.profileConnected,
  );

  const missingRequiredCalendarProfiles = requiredCalendarProfiles?.filter(
    (requiredProfile) =>
      !calendarProfiles?.some(
        (calendarProfile) =>
          calendarProfile.platform === requiredProfile.calendarPlatform ||
          calendarProfile.providerService === requiredProfile.calendarPlatform,
      ),
  );

  const missingRequiredConferencingProfiles =
    requiredConferencingProfiles?.filter(
      (requiredProfile) =>
        !conferencingProfiles?.some(
          (conferencingProfile) =>
            conferencingProfile.platform === requiredProfile.videoPlatform,
        ),
    );

  const missingCronofyTimezone = cronofyTimezone === null;

  const hasAllRequiredProfiles =
    calendarConnected &&
    missingRequiredCalendarProfiles?.length === 0 &&
    missingRequiredConferencingProfiles?.length === 0;

  return {
    calendarConnected: Boolean(calendarConnected),
    calendarProfiles,
    hasAllRequiredProfiles: Boolean(hasAllRequiredProfiles),
    missingCronofyTimezone,
    disconnectedCalendarProfiles,
    missingRequiredCalendarProfiles,
    conferencingProfiles,
    disconnectedConferencingProfiles,
    missingRequiredConferencingProfiles,
    requiredCalendarProfiles,
    requiredConferencingProfiles,
    loading,
    error,
    ...data?.self?.coachData,
  };
};
