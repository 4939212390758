import React from 'react';
import styled from 'styled-components';
import LogoLogin from '../assets/images/logo-login.svg';
import { BACKGROUND } from '../theme';

interface Props {
  children?: React.ReactNode;
}

const LogoWrapper = styled.div`
  width: 100%;
  padding-bottom: 175px;
  flex-direction: column;
  position: sticky;
  margin-top: 40px;
`;

const Logo = styled.img`
  width: 92px;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: ${BACKGROUND};
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
`;

const LoginLayout: React.FC<Props> = ({ children }) => (
  <LoginContainer>
    <LogoWrapper>
      <Logo src={LogoLogin} alt="EXOS Coach Hub logo" />
    </LogoWrapper>
    {children}
  </LoginContainer>
);

export default LoginLayout;
