import { ScreenNames } from '../utils/trackingEvents';

export const getScreenName = (pathname: string) => {
  if (pathname === '/app' || pathname === '/app/') {
    return ScreenNames.HomePage;
  }

  if (pathname === '/app/members' || pathname === '/app/members/') {
    return ScreenNames.MemberList;
  }

  if (pathname.match(/\/app\/members\/.*\/gameplan\/.*\/edit\/?$/)) {
    return ScreenNames.EditGameplan;
  }

  if (pathname.match(/\/app\/members\/.*\/gameplan\/?$/)) {
    return ScreenNames.CreateGameplan;
  }

  if (pathname.match(/\/app\/members\/.*\/gameplan\/.*$/)) {
    return ScreenNames.ViewGameplan;
  }

  if (pathname.match(/\/app\/members\/.*$/)) {
    return ScreenNames.MemberProfile;
  }

  if (pathname === '/app/sessions' || pathname === '/app/sessions/') {
    return ScreenNames.SessionsView;
  }
  if (pathname === '/app/methodology' || pathname === '/app/methodology/') {
    return ScreenNames.Methodology;
  }

  if (
    pathname === '/app/settings/availability' ||
    pathname === '/app/settings/availability/'
  ) {
    return ScreenNames.Availability;
  }

  if (
    pathname === '/app/settings/preferences' ||
    pathname === '/app/settings/preferences/'
  ) {
    return ScreenNames.Preferences;
  }

  if (
    pathname === '/app/settings/connections' ||
    pathname === '/app/settings/connections/'
  ) {
    return ScreenNames.Connections;
  }

  return ScreenNames.Unknown;
};
