import styled from 'styled-components';

export const SignInWrapper = styled.div`
  display: flex;
`;

export const LoginForm = styled.form`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 400px;
  border: none;
  background-color: transparent;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

export const CoverImage = styled.img`
  height: 100vh;
`;
