export enum SplitFlags {
  MOCK_FLAG = 'mock-flag', // for use in unit tests that require `SplitFlags` but that aren't coupled to a specific feature flag
  CH_UNREAD_COUNT_SUBSCRIPTIONS = 'ch-unread-count-subscriptions',
  CH_REPORT_EXTERNAL_SESSIONS = 'ch-report-external-sessions',
  CH_CONTENT_LIBRARY_FEEDBACK = 'ch-content-library-feedback',
  METHODOLOGY_BOT_ENABLED = 'methodology-bot-enabled',
  CH_MULTI_COACH_CHAT = 'ch-multi-coach-chat',
  CH_GAMEPLAN_PQS = 'ch-gameplan-pqs',
  NS_HOME_PAGE = 'ch-nutrition-specialist-home',
}
