import React from 'react';
import {
  FieldValues,
  useForm as useFormNative,
  UseFormProps as UseFormPropsNative,
  UseFormReturn as UseFormReturnNative,
} from 'react-hook-form';

interface UseFormMethods<
  TFieldValues extends FieldValues,
  TContext extends object = object,
> extends UseFormReturnNative<TFieldValues, TContext> {
  isValidLocal: boolean;
  setServerError: (message: string) => void;
  clearServerError: () => void;
}

const useForm = <
  TFieldValues extends FieldValues & { server: string } = FieldValues & {
    server: string;
  },
  TContext extends object = object,
>(
  options: UseFormPropsNative<TFieldValues, TContext>,
): UseFormMethods<TFieldValues, TContext> => {
  const nativeMethods = useFormNative<TFieldValues, TContext>(options);
  const { clearErrors, handleSubmit, setError } = nativeMethods;
  const isValidLocal = !Object.keys(nativeMethods.formState.errors).some(
    (e) => e !== 'server',
  );

  const clearServerError = React.useCallback(() => {
    clearErrors('server' as any);
  }, [clearErrors]);

  return {
    ...nativeMethods,
    handleSubmit: (isValid, isInvalid) => {
      const wrappedOnPress = handleSubmit(isValid, isInvalid);
      return async () => {
        clearServerError();

        return wrappedOnPress();
      };
    },
    setServerError: (message) => {
      setError('server' as any, { types: { server: message } });
    },
    clearServerError,
    isValidLocal,
  };
};

export default useForm;
