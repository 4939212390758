import React, { useMemo } from 'react';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { ClientCalendarPlatform } from '../../graphql/types';
import { useGetConnectionDetails } from '../../hooks/useGetConnectionDetails';
import useTracking from '../../hooks/useTracking';
import { STATUS_ERROR } from '../../theme';
import {
  EventCategories,
  EventLabels,
  EventNames,
  EventSectionNames,
} from '../../utils/trackingEvents';
import Button, { ButtonSize, ButtonVariant } from '../reusable/Button';
import { StyledGlobalAlertItem } from '../reusable/Snackbar/GlobalAlert';
import Text, { TextKind } from '../reusable/Text';

const FIX_CONNECTION_CTA = 'Fix Connection';

interface AlertConfig {
  name: string;
  id: string;
  action: () => void;
  actionCta: string;
}

export const FloatingAlerts = () => {
  const {
    disconnectedCalendarProfiles,
    disconnectedConferencingProfiles,
    requiredCalendarProfiles,
    requiredConferencingProfiles,
  } = useGetConnectionDetails();

  const { track } = useTracking();
  const navigate = useNavigate();

  const alerts = useMemo<AlertConfig[]>(() => {
    const handleFixConnection = () => {
      track(EventNames.ButtonClick, {
        event_category: EventCategories.Settings,
        event_label: EventLabels.FixConnections,
        section_name: EventSectionNames.FloatingAlert,
      });

      navigate('/app/settings/connections');
    };

    const calendarAlerts: AlertConfig[] = (disconnectedCalendarProfiles ?? [])
      .filter((disconnectedCalendar) => {
        const isExosCalendar =
          disconnectedCalendar.platform === ClientCalendarPlatform.ExosOnly;
        const isRequiredCalendar = requiredCalendarProfiles?.some(
          (c) => disconnectedCalendar.platform === c.calendarPlatform,
        );
        return isExosCalendar || isRequiredCalendar;
      })
      .map((profile) => {
        const calendarName =
          profile.platform === ClientCalendarPlatform.ExosOnly
            ? `Exos calendar`
            : 'client calendar';
        return {
          id: profile.profileId,
          name: `Your ${calendarName} has been disconnected.`,
          action: handleFixConnection,
          actionCta: FIX_CONNECTION_CTA,
        };
      });
    const conferenceAlerts: AlertConfig[] = (
      disconnectedConferencingProfiles ?? []
    )
      .filter((disconnectedConferencingProfile) =>
        requiredConferencingProfiles?.some(
          (c) => disconnectedConferencingProfile.platform === c.videoPlatform,
        ),
      )
      .map((profile) => ({
        id: profile.profileId,
        name: `Your video conferencing profile has been disconnected.`,
        action: handleFixConnection,
        actionCta: FIX_CONNECTION_CTA,
      }));

    return [...calendarAlerts, ...conferenceAlerts];
  }, [
    disconnectedCalendarProfiles,
    disconnectedConferencingProfiles,
    requiredCalendarProfiles,
    requiredConferencingProfiles,
    track,
    navigate,
  ]);

  if (alerts.length === 0) {
    return null;
  }

  const globalAlertContainer = document.getElementById(
    'global-alert-container',
  );

  if (!globalAlertContainer) {
    return null;
  }

  return createPortal(
    <>
      {alerts.map((alert) => (
        <StyledGlobalAlertItem key={alert.id} data-testid="floating-alert">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            color={STATUS_ERROR}
            fontSize={16}
          />
          <Text kind={TextKind.BodyS} testId="floatingAlert-name">
            {alert.name}
          </Text>
          <Button
            variant={ButtonVariant.Dark}
            size={ButtonSize.Medium}
            onClick={alert.action}
          >
            {alert.actionCta}
          </Button>
        </StyledGlobalAlertItem>
      ))}
    </>,
    globalAlertContainer,
  );
};
