import React, { lazy, Suspense, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useAuth } from '@teamexos/fit-shared';
import {
  createBrowserRouter,
  createMemoryRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { FloatingAlerts } from '../components/FloatingAlerts';
import PrivateRoute from '../components/PrivateRoute';
import { LoadingPage } from '../components/reusable/LoadingPage';
import SignIn from '../components/SignIn';
import { CoachType, useSelfQuery } from '../graphql/types';
import { SplitFlags } from '../hooks/featureFlags';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import { mixpanelClient } from '../utils/mixpanelClient';
import Playground from './playground';

const GamePlanEditor = lazy(() =>
  import('../components/MemberProfile/MemberGameplans/GameplanEditor').then(
    (module) => ({ default: module.GamePlanEditor }),
  ),
);

const CoachSettings = lazy(() =>
  import('../components/CoachSettings').then((module) => ({
    default: module.CoachSettings,
  })),
);

const MemberGate = lazy(() => import('../components/MemberGate'));
const HomePage = lazy(() => import('../components/HomePage'));
const Member = lazy(() => import('../components/MemberProfile'));
const Members = lazy(() => import('../components/Members'));
const Sessions = lazy(() => import('../components/Sessions'));
const Methodology = lazy(() => import('../components/Methodology'));

const PageSuspense: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Suspense fallback={<LoadingPage />}>{children}</Suspense>
);

const MainScreen: React.FC = () => {
  const { data, loading } = useSelfQuery();
  const { flags } = useFeatureFlags();
  const isHomeEnabledForNs = flags[SplitFlags.NS_HOME_PAGE];

  const coachType = data?.self?.coachData?.type;

  if (loading) {
    return null;
  }

  if (
    coachType === CoachType.Coach ||
    (isHomeEnabledForNs && coachType === CoachType.Dietitian)
  ) {
    return <HomePage />;
  }

  if (coachType === CoachType.Dietitian) {
    return <Sessions />;
  }

  return <MemberGate />;
};

const AppWrapper = () => {
  const { isLoggedIn, isLoading: isAuthLoading } = useAuth();
  const { data } = useSelfQuery({
    skip: !isLoggedIn || isAuthLoading,
  });

  useEffect(() => {
    if (!data?.self?.id) {
      return;
    }

    Sentry.setUser({ id: data.self.id });
    mixpanelClient.identify(data.self.id);
  }, [data?.self?.id]);

  return (
    <>
      {isLoggedIn && <FloatingAlerts />}
      <Outlet />
    </>
  );
};

const routesConfig = createRoutesFromElements(
  <Route path="/">
    {/* Redirect from / to /app */}
    <Route index element={<Navigate to="/app" replace />} />
    <Route path="app">
      <Route path="login" element={<SignIn />} />
      <Route
        path="playground"
        element={
          process.env.GATSBY_ENV_LEVEL === 'dev' ? (
            <Playground />
          ) : (
            <Navigate to="/app" replace />
          )
        }
      />
      <Route element={<AppWrapper />}>
        <Route
          index
          element={
            <PrivateRoute>
              <PageSuspense>
                <MainScreen />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="fitMembersList"
          path="members"
          element={
            <PrivateRoute allowedCoachTypes={[CoachType.Coach]}>
              <PageSuspense>
                <Members />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="membersProfile"
          path="members/:memberId"
          element={
            <PrivateRoute
              allowedCoachTypes={[CoachType.Coach, CoachType.Dietitian]}
            >
              <PageSuspense>
                <Member />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="gameplanCreator"
          path="members/:memberId/gameplan"
          element={
            <PrivateRoute allowedCoachTypes={[CoachType.Coach]}>
              <PageSuspense>
                <GamePlanEditor state="create" />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="gameplanViewer"
          path="members/:memberId/gameplan/:planId"
          element={
            <PrivateRoute
              allowedCoachTypes={[CoachType.Coach, CoachType.Dietitian]}
            >
              <PageSuspense>
                <GamePlanEditor state="view" />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="gameplanEditor"
          path="members/:memberId/gameplan/:planId/edit"
          element={
            <PrivateRoute
              allowedCoachTypes={[CoachType.Coach, CoachType.Dietitian]}
            >
              <PageSuspense>
                <GamePlanEditor state="edit" />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="sessions"
          path="sessions"
          element={
            <PrivateRoute
              allowedCoachTypes={[CoachType.Coach, CoachType.Dietitian]}
            >
              <PageSuspense>
                <Sessions />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="methodology"
          path="methodology"
          element={
            <PrivateRoute
              allowedCoachTypes={[CoachType.Coach, CoachType.Dietitian]}
            >
              <PageSuspense>
                <Methodology />
              </PageSuspense>
            </PrivateRoute>
          }
        />
        <Route
          key="coachSettings"
          path="settings/:settingsPage"
          element={
            <PrivateRoute
              allowedCoachTypes={[CoachType.Coach, CoachType.Dietitian]}
            >
              <PageSuspense>
                <CoachSettings />
              </PageSuspense>
            </PrivateRoute>
          }
        />
      </Route>
    </Route>
    <Route
      path="settings"
      element={<Navigate to="/app/settings/availability" replace />}
    />
    {/* Redirect from /anything to /app - this is required in addition to the redirect above */}
    <Route path="*" element={<Navigate to="/app" replace />} />
  </Route>,
);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const browserRouter = sentryCreateBrowserRouter(routesConfig);
export const BrowserRouter = () => <RouterProvider router={browserRouter} />;

// Browser router doesn't work in jest tests and we can't export non-react components here
// so we need to create a memory router for tests https://github.com/vitejs/vite/discussions/4583
const memoryRouter = (initialEntries: string[]) =>
  createMemoryRouter(routesConfig, {
    initialEntries,
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      v7_skipActionErrorRevalidation: true,
    },
  });
export const TestRouter = ({
  initialEntries,
}: {
  initialEntries: string[];
}) => (
  <RouterProvider
    router={memoryRouter(initialEntries)}
    future={{ v7_startTransition: true }}
  />
);
